import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './interceptors/axios'
import Quasar from 'quasar/src/vue-plugin.js';import Loading from 'quasar/src/plugins/Loading.js';
import quasarUserOptions from './quasar-user-options'
import lang from 'quasar/lang/es';
import { useCookies } from 'vue3-cookies';
import dayjs from "dayjs";

require('dayjs/locale/es')

var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
dayjs.locale('es')

createApp(App)
    .use(Quasar, quasarUserOptions)
    .use(lang)
    .use(router)
    .use(useCookies)
    .mount('#app');