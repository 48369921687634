import { createRouter, createWebHistory } from 'vue-router'

const routes = [

    {
        path: '/login',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/', name: 'Landing', component: () => import('@/views/Home.vue'), meta: { title: "", icon: '' },
        children: [
            { name: 'dashboard', path: '/dashboard', component: () => import("@/views/landing/Dashboard.vue"), meta: { title: "", icon: '' } },

            { name: 'listCertificado', path: '/certificado', component: () => import("@/views/certificado/List.vue"), meta: { title: "Certificados", icon: 'post_add' } },
            { name: 'editCertificado', path: '/certificado/edit/:id', component: () => import("@/views/certificado/Editor.vue"), meta: { title: "Certificado", icon: 'newspaper' } },
            { name: 'createCertificado', path: '/certificado/edit', component: () => import("@/views/certificado/Editor.vue"), meta: { title: "Certificado", icon: 'newspaper' } },

            { name: 'listCotizacion', path: '/cotizacion', component: () => import("@/views/cotizacion/List.vue"), meta: { title: "Solicitudes", icon: 'bookmarks' } },
            { name: 'editCotizacion', path: '/cotizacion/edit/:id', component: () => import("@/views/cotizacion/Editor.vue"), meta: { title: "Solicitud", icon: 'bookmarks' } },

            { name: 'listCotizacionEliminada', path: '/cotizacionEliminada', component: () => import("@/views/cotizacionEliminada/List.vue"), meta: { title: "Solicitudes Eliminadas", icon: 'bookmark_remove' } },
            { name: 'editCotizacionEliminada', path: '/cotizacionEliminada/edit/:id', component: () => import("@/views/cotizacionEliminada/Editor.vue"), meta: { title: "Solicitud Eliminada", icon: 'bookmark_remove' } },

            { name: 'listCliente', path: '/cliente', component: () => import("@/views/cliente/List.vue"), meta: { title: "Clientes", icon: 'contact_page' } },
            { name: 'editCliente', path: '/cliente/edit/:id', component: () => import("@/views/cliente/Editor.vue"), meta: { title: "Cliente", icon: 'contact_page' } },
            { name: 'createCliente', path: '/cliente/edit', component: () => import("@/views/cliente/Editor.vue"), meta: { title: "Cliente", icon: 'contact_page' } },

            { name: 'listAgente', path: '/agente', component: () => import("@/views/agente/List.vue"), meta: { title: "Agentes", icon: 'business' } },
            { name: 'editAgente', path: '/agente/edit/:id', component: () => import("@/views/agente/Editor.vue"), meta: { title: "Agente", icon: 'business' } },
            { name: 'createAgente', path: '/agente/edit', component: () => import("@/views/agente/Editor.vue"), meta: { title: "Agente", icon: 'business' } },

            { name: 'listMoneda', path: '/moneda', component: () => import("@/views/moneda/List.vue"), meta: { title: "Monedas", icon: 'currency_exchange' } },

            { name: 'listViaTransporte', path: '/via', component: () => import("@/views/viaTransporte/List.vue"), meta: { title: "Vias de Transporte", icon: 'directions_boat' } },

            { name: 'listComisarioAverias', path: '/comisario', component: () => import("@/views/comisarioAveria/List.vue"), meta: { title: "Comisarios de Averías", icon: 'travel_explore' } },

            { name: 'listPais', path: '/pais', component: () => import("@/views/pais/List.vue"), meta: { title: "Paises", icon: 'tour' } },

            { name: 'integracion', path: '/integracion', component: () => import("@/views/integracion/List.vue"), meta: { title: "Remesas de Producción", icon: 'laptop' } },
            { name: 'editIntegracion', path: '/integracion/edit/:id', component: () => import("@/views/integracion/Editor.vue"), meta: { title: "Remesa de Producción", icon: 'laptop' } },

            { name: 'listFactura', path: '/factura', component: () => import("@/views/factura/List.vue"), meta: { title: "Facturas", icon: 'receipt_long' } },
            { name: 'createFactura', path: '/factura/edit', component: () => import("@/views/factura/Editor.vue"), meta: { title: "Factura", icon: 'receipt_long' } },
            { name: 'editFactura', path: '/factura/edit/:id', component: () => import("@/views/factura/Editor.vue"), meta: { title: "Factura", icon: 'receipt_long' } },

            { name: 'listCheque', path: '/cheque', component: () => import("@/views/cheque/List.vue"), meta: { title: "Cheques", icon: 'payments' } },
            { name: 'createCheque', path: '/cheque/edit', component: () => import("@/views/cheque/Editor.vue"), meta: { title: "Cheque", icon: 'payments' } },
            { name: 'editCheque', path: '/cheque/edit/:id', component: () => import("@/views/cheque/Editor.vue"), meta: { title: "Cheque", icon: 'payments' } },

            { name: 'listLiquidacion', path: '/liquidacion', component: () => import("@/views/liquidacion/List.vue"), meta: { title: "Liquidaciones", icon: 'business_center' } },
            { name: 'createLiquidacion', path: '/liquidacion/edit', component: () => import("@/views/liquidacion/Editor.vue"), meta: { title: "Liquidación", icon: 'business_center' } },
            { name: 'editLiquidacion', path: '/liquidacion/edit/:id', component: () => import("@/views/liquidacion/Editor.vue"), meta: { title: "Liquidación", icon: 'business_center' } },

            { name: 'listValores', path: '/valores', component: () => import("@/views/valores/List.vue"), meta: { title: "Remesas de Valores", icon: 'request_quote' } },
            { name: 'createValores', path: '/valores/edit', component: () => import("@/views/valores/Editor.vue"), meta: { title: "Remesa de Valores", icon: 'request_quote' } },
            { name: 'editValores', path: '/valores/edit/:id', component: () => import("@/views/valores/Editor.vue"), meta: { title: "Remesa de Valores", icon: 'request_quote' } },

            { name: 'listUsuarioAdministrador', path: '/usuarioAdministrador', component: () => import("@/views/usuarioAdministrador/List.vue"), meta: { title: "Usuarios Administradores", icon: 'people_alt' } },
            { name: 'createUsuarioAdministrador', path: '/usuarioAdministrador/edit', component: () => import("@/views/usuarioAdministrador/Editor.vue"), meta: { title: "Usuario Administrador", icon: 'people_alt' } },
            { name: 'editUsuarioAdministrador', path: '/usuarioAdministrador/edit/:id', component: () => import("@/views/usuarioAdministrador/Editor.vue"), meta: { title: "Usuario Administrador", icon: 'people_alt' } }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router