import axios from "axios";

let refresh = false;

fetch("/api.json")
    .then(r => r.json())
    .then(apiData => {
        axios.defaults.baseURL = apiData.apiUrl;
    },
    response => {
        console.log('Error loading json:', response)
    });
