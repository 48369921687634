import 'quasar/dist/quasar.css'
import langEs from 'quasar/lang/es'
import '@quasar/extras/material-icons/material-icons.css'
import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';;

// To be used on app.use(Quasar, { ... })
export default {
    config: {
        brand: {
            primary: '#5489d6',
            secondary: '#0052a3',
            accent: '#704e8b',

            dark: '#1d1d1d',
            'dark-page': '#121212',

            positive: '#00d66c',
            negative: '#a30000',
            info: '#00d6d6',
            warning: '#fcc717'
        }
    },
    plugins: { Loading, Dialog, Notify },
    lang: langEs
}